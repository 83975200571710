import React from 'react'
import "./create-account.css"
import {TextField} from "@mui/material"
import {LocalizationProvider} from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createAccountApi } from '../../../../Api';
import { useNavigate } from 'react-router-dom';
import { compressImg } from "../../../../ImageCompressor";
import Swal from "sweetalert2";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CreateAccount() {
    const navigate = useNavigate();
    const fileIp = React.useRef(null);
    const [img, setImg] = React.useState('https://placehold.co/250x100');
    const [empObj, setEmpObj] = React.useState({
        business_name: "",
        account_type: "",
        email: "",
        phone: "",
        website: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        validity_start_date: "",
        validity_end_date: "",
        no_of_employees: "",
        no_of_locations: "",
        plan_name: "",
        logo: "",
        notification_mobile: '',
        start_time: '',
        end_time: ''
    })

    const handleReset = () => {
        if(fileIp.current) {
            fileIp.current.value = "";
            fileIp.current.type = "text";
            fileIp.current.type = "file";
        }
    };

    const handleFile = (event) => {
        console.log(event);
        setImg('https://placehold.co/250x100');
        const file = event.target.files[0];
        console.log('Before compression', file.size)
        let splitfile = file.name;
        let extension = splitfile.split('.').pop();
        if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
            console.log('valid file');
            var reader = new FileReader();
            if(event.target.files[0]){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e)=>{
                    setImg(e.target.result);
                }
                compressImg(event.target.files[0]).then(img => {
                    setEmpObj({...empObj, logo: img});
                    console.log('After compression', img.size)
                });
            }
        }else{
            Swal.fire({
                text: 'Invalid file format. Only .png, .jpg files are allowed',
                icon: 'warning',
                heightAuto: false
            })
            return
        }
    }

    const submit = async () => {
        Swal.fire({
            html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
            showConfirmButton: false,
            heightAuto: false,
            customClass: {popup: 'loader-swal'}
        });
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(!empObj.business_name){
            Swal.fire({
                text: 'Please provide Business name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.account_type){
            Swal.fire({
                text: 'Please select Account type!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.email){
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!emalval.test(empObj.email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(empObj.notification_email && !emalval.test(empObj.notification_email)){
            Swal.fire({
                text: 'Please enter valid Notification Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.phone){
            Swal.fire({
                text: 'Mobile number required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.validity_start_date){
            Swal.fire({
                text: 'Validity start date required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.validity_end_date){
            Swal.fire({
                text: 'Validity end date required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.account_code){
            Swal.fire({
                text: 'Account code required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.no_of_employees){
            Swal.fire({
                text: 'No of employees required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.no_of_locations){
            Swal.fire({
                text: 'No of locations required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.pic_name){
            Swal.fire({
                text: 'Pic Name required!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let formData = new FormData();
            for(let val of Object.keys(empObj)){
                if(val === "logo" && !empObj[val]){
                }else if(val === "logo" && empObj[val]){
                    formData.append(val, empObj[val]);
                }else if(val === "email"){
                    formData.append(val, empObj[val].toLowerCase());
                }else{
                    formData.append(val, empObj[val]);
                }
            }
            let apiData = await createAccountApi(formData);
            Swal.fire({
                html: `<img src="../loader.gif" style="width: 50px" alt="loader">`,
                showConfirmButton: false,
                heightAuto: false,
                customClass: {popup: 'loader-swal'},
                timer: 2000
            });
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'Account created successfully',
                    icon: 'success',
                    heightAuto: false,
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    navigate('/admin/manage-accounts')
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'E' && apiData.error === "Only image files are allowed!"){
                Swal.fire({
                    text: "Please select valid image file",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
            if(apiData.status === 'F' && apiData.message === "Server error"){
                Swal.fire({
                    text: apiData.errorDescription,
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        }
    }
    
    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Create Account</h5>
            <div className="card forms-card">
                <div className="row mb-3">
                    <div className="col-12 text-center">
                        <h5 className="mb-3"><strong>Logo</strong></h5>
                        <img src={img} alt="" style={{maxHeight: '100px', maxWidth: '100%'}} />
                        <br />
                        <input type="file" accept=".png, .jpg, .jpeg, .webp" hidden ref={fileIp} onClick={handleReset} onChange={(ev) => {handleFile(ev)}} />
                        <button className="btn btn-dark px-4 my-3" onClick={() => {fileIp.current.click();}}>Upload</button>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Business Name" variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                setEmpObj({...empObj, business_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={empObj.account_type}
                                label="Account Type"
                                onChange={(ev) => {
                                    setEmpObj({...empObj, account_type: ev.target.value})
                                }}
                            >
                                <MenuItem value={'cms'}>CMS</MenuItem>
                                <MenuItem value={'ams'}>AMS</MenuItem>
                                <MenuItem value={'bni'}>BNI</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                setEmpObj({...empObj, email: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Notification Email" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, notification_email: ev.target.value})
                            }}
                        />
                    </div>
                    {empObj && empObj.account_type === 'ams' && <>
                        <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Notification Mobile" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, notification_mobile: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Start Time (HH:MM:SS)" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, start_time: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="End Time (HH:MM:SS)" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, end_time: ev.target.value})
                            }}
                        />
                    </div>
                    </>}
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Mobile Number" variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                setEmpObj({...empObj, phone: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Website" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, website: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='w-100' label="Validity Start Date*" onChange={(ev) => {
                                let date = dayjs(ev.$d).format('YYYY-MM-DD');
                                setEmpObj({...empObj, validity_start_date: date})
                            }} />
                        </LocalizationProvider>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='w-100' label="Validity End Date*" onChange={(ev) => {
                                let date = dayjs(ev.$d).format('YYYY-MM-DD');
                                setEmpObj({...empObj, validity_end_date: date})
                            }} />
                        </LocalizationProvider>
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Account Code" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, account_code: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="City" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, city: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="State" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, state: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Country" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, country: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <TextField className='w-100' rows={3} multiline id="outlined-basic" label="Address" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, address: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" type={'number'} label="No of Employees" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, no_of_employees: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" type={'number'} label="No of Locations" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, no_of_locations: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Plan Name" variant="outlined" autoComplete="off"
                            onChange={(ev) => {
                                setEmpObj({...empObj, plan_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Person in charge / PIC Name*" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, pic_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="PIC Phone" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, pic_phone: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="PIC Email" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, pic_email: ev.target.value})
                            }}
                        />
                    </div>
                </div>
                <div className="row buttonsRow">
                  <button className='createButton me-4' onClick={() => {submit()}}>Create</button>
                  <button className='cancelButton' onClick={() => {navigate('/admin/manage-accounts')}}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount
