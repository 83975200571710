import React, { useEffect, useState } from 'react'
import "./create.css"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createEmp, getLocations } from '../../Api';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

function CreateEmployee() {
    const navigate = useNavigate();
    const [locations, setLocations] = useState([])
    const [userInfo, setUserInfo] = useState(null)
    const [empObj, setEmpObj] = React.useState({
        emp_code: "",
        emp_name: "",
        email: "",
        phone: "",
        designation: "",
        dob: "",
        location_code: "",
        address: "",
        city: "",
        state: "",
        country: "",
        account_code: "",
        account_name: "",
    })

    const submit = async () => {
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(!empObj.emp_name){
            Swal.fire({
                text: 'Please provide Employee name!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.emp_code){
            Swal.fire({
                text: 'Please provide Employee code!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(empObj.email && !emalval.test(empObj.email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.phone){
            Swal.fire({
                text: 'Please provide Mobile Number!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!empObj.designation){
            Swal.fire({
                text: 'Please provide Designation!',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }
        // else if(!empObj.dob){
        //     Swal.fire({
        //         text: 'Date of Birth required!',
        //         icon: 'warning',
        //         showConfirmButton: false,
        //         heightAuto: false,
        //         timer: 1500
        //     });
        // }
        else if(userInfo && userInfo.account_type === 'ams' && locations.length < 1){
            Swal.fire({
                text: 'Create a location before adding an employee',
                icon: 'warning',
                // showConfirmButton: false,
                confirmButtonColor: '#000',
                heightAuto: false,
                // timer: 1500
            }).then(() => {
                navigate('/app/create-location')
            });
        }
        else if(userInfo && userInfo.account_type === 'ams' && !empObj.location_code){
            Swal.fire({
                text: 'Select Location',
                icon: 'warning',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let data = {...empObj};
            data.email = data.email.toLowerCase();
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            data.account_code = userData.account_code;
            data.account_name = userData.business_name;
            let apiData = await createEmp({...data});
            console.log(apiData);
            if(apiData.status === "S"){
                Swal.fire({
                    text: 'Employee created successfully',
                    showConfirmButton: false,
                    icon: 'success',
                    timer: 3000,
                }).then(() => {
                    navigate('/app/manage-employees')
                })
            }
            if(apiData.status === "E" && apiData.result_code === 202){
                Swal.fire({
                    html: 'Limit Exceeded. <br> Please contact AttendLog team to upgrade your plan.',
                    // showConfirmButton: false,
                    confirmButtonColor: '#000',
                    icon: 'warning'
                })
            }else if(apiData.status === "E" && apiData.result_code === 201){
                Swal.fire({
                    text: apiData.message,
                    showConfirmButton: false,
                    icon: 'warning',
                    timer: 3000,
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        }
    }
    
    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let acc_code = userData.account_code;
            setUserInfo(userData);
            let apiData = await getLocations(acc_code);
            apiData.status === 'S' ? setLocations(apiData.result_info) : setLocations([]);
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Missing token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
            if(apiData.status === 'F' && apiData.message === "Unauthorized - Invalid token"){
                Swal.fire({
                    text: "Please login with your credentials",
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    localStorage.clear();
                    navigate('/app/login');
                })
            }
        })()
    },[navigate])
    return (
        <div className='employeeContainer'>
            <h5 className='create-employee'>Create Employee</h5>
            <div className="card forms-card">
                <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Employee Name" variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                setEmpObj({...empObj, emp_name: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Employee Code" value={empObj.emp_code || ''} variant="outlined" autoComplete="off" required 
                            onChange={(ev) => {
                                let val = ev.target.value.toLowerCase();
                                let regex = /[^a-z0-9_-]/gi;
                                setEmpObj({...empObj, emp_code: val.replace(regex, "")})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Email" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, email: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Mobile Number" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, phone: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Designation" variant="outlined" autoComplete="off" required
                            onChange={(ev) => {
                                setEmpObj({...empObj, designation: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker className='w-100' label="Date of Birth" onChange={(ev) => {
                                let date = ev?.$d;
                                setEmpObj({...empObj, dob: dayjs(date).format('YYYY-MM-DD')})
                            }} />
                        </LocalizationProvider>
                    </div>
                    {userInfo && userInfo.account_type === 'ams' && <div className="col-12 col-lg-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" style={{paddingRight: 5}}>Select Location *</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={empObj.location_code}
                                label="Select Location"
                                onChange={(ev) => {
                                    setEmpObj({...empObj, location_code: ev.target.value})
                                }}
                            >
                               {locations && locations.map((location) => <MenuItem key={'location-'+location.id} value={location.location_code}>{location.location_name} </MenuItem>)}
                                
                            </Select>
                        </FormControl>
                    </div>}
                    {/* <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="City" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, city: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="State" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, state: ev.target.value})
                            }}
                        />
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                        <TextField className='w-100' id="outlined-basic" label="Country" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, country: ev.target.value})
                            }}
                        />
                    </div> */}
                    <div className="col-12 mb-3">
                        <TextField className='w-100' multiline rows={3} id="outlined-basic" label="Address" variant="outlined" autoComplete="off" 
                            onChange={(ev) => {
                                setEmpObj({...empObj, address: ev.target.value})
                            }}
                        />
                    </div>
                </div>
                {/* <div className="row mb-4">
                    <div className="col-12 col-lg-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel className='w-100' id="demo-simple-select-label">Blood Group</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={empObj.blood_group}
                                label="Blood Group"
                                onChange={(ev) => {
                                    setEmpObj({...empObj, blood_group: ev.target.value})
                                }}
                            >
                            <MenuItem value={"O+"}>O+</MenuItem>
                            <MenuItem value={"O-"}>O-</MenuItem>
                            <MenuItem value={"A+"}>A+</MenuItem>
                            <MenuItem value={"A-"}>A-</MenuItem>
                            <MenuItem value={"B+"}>B+</MenuItem>
                            <MenuItem value={"B-"}>B-</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div> */}
                <div className="row buttonsRow">
                <button className='createButton me-4' onClick={() => {submit()}}>Create</button>
                <button className='cancelButton' onClick={() => {navigate('/app/manage-employees')}}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateEmployee
