import React, { useEffect, useRef, useState } from 'react';
import './capturelogin.css';
import { useParams } from 'react-router-dom';
import { getEmployeesData, getEmployeeAttendanceInfo, markLogin, markLogout } from '../../Api';
import { compressImg } from '../../ImageCompressor';
import Swal from 'sweetalert2';
import {TextField,Autocomplete} from '@mui/material';
import * as geolib from 'geolib';

function CaptureLogin() {
  const [isThank, setIsThank] = useState(null);
  const [isInRange, setIsInRange] = useState(false);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const {location_code, account_code} = useParams();
  const fileIp = useRef();
  const [accInfo, setAccInfo] = useState({account_type: '', account_code: '', account_name: '', location_name: '', account_logo: '', latitude: null, longitude: null, employees: []});
  const [employees, setEmployees] = useState([]);
  const [value, setValue] = useState({});
  const [img, setImg] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [logType, setLogType] = useState('');
  const [empLog,setEmpLog] = useState({});

  const handleImage = (event) => {
    setImg('');
    const file = event.target.files[0];
    console.log('Before compression', file.size)
    let splitfile = file.name;
    let extension = splitfile.split('.').pop();
    if(extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
        console.log('valid file');
        var reader = new FileReader();
        if(event.target.files[0]){
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (e)=>{
                setImg(e.target.result);
            }
            compressImg(event.target.files[0]).then(img => {
                setImgFile(img);
                console.log('After compression', img.size)
            });
        }
    }else{
        Swal.fire({
            text: 'Invalid file format. Only .png, .jpg files are allowed',
            icon: 'warning',
            heightAuto: false
        })
        return
    }
  }

  const submit = async (submitType) => {
    if (!position.latitude && ("geolocation" in navigator)) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
      });
      Swal.fire({
        text: 'Geolocation is not enabled',
        icon: 'warning',
        heightAuto: false,
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }else if(!imgFile){
      Swal.fire({
        text: 'Please take selfie',
        icon: 'warning',
        heightAuto: false,
        showConfirmButton: false,
        timer: 3000
      })
    }else {
      let formData =  new FormData();
      if(submitType !== 'logout'){
        formData.append("account_code", accInfo.account_code);
        formData.append("account_name", accInfo.account_name);
        formData.append("location_code", location_code);
        formData.append("location_name", accInfo.location_name);
        formData.append("emp_name", value.emp_name);
        formData.append("emp_code", value.emp_code);
        formData.append("latitude", position.latitude);
        formData.append("longitude", position.longitude);
      }else{
        formData.append("correl_id", empLog.correl_id);
        formData.append("checkout_latitude", position.latitude);
        formData.append("checkout_longitude", position.longitude);
      }
      if(imgFile){
        formData.append("selfie", imgFile);
      }
      let apiData;
      if(submitType === 'login'){
        apiData = await markLogin(formData);
      }else if(submitType === 'logout'){
        apiData = await markLogout(formData);
      }
      if(apiData.status === "S"){
        Swal.fire({
          text: 'Success',
          icon: 'success',
          heightAuto: false,
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
            setIsThank(true);
            if(submitType === 'login'){
              setLogType('Login');
            }else if(submitType === 'logout'){
              setLogType('Logout');
            }
        })
      }else{
        Swal.fire({
          text: JSON.stringify(apiData),
          icon: 'error',
          heightAuto: false,
          showConfirmButton: false,
          timer: 3000
        })
      } 
    }
  }

  const checkCameraAccess = (fromType) => {
    if (fileIp && fileIp.current && fromType === "user") {
      fileIp.current.click();
      fetchAttendanceLog();
    }
  };

  const fetchAttendanceLog = async () => {
    let emp_code = value.emp_code;
    let apiData = await getEmployeeAttendanceInfo(accInfo.account_code, emp_code);
      if(apiData.status === "S"){
        apiData.attendance_info.length > 0 ? setEmpLog(apiData.attendance_info[0]) : setEmpLog({})
        // if((apiData.attendance_info.length > 0) && !apiData.attendance_info[0].checkout_time){
        // }else if((apiData.attendance_info.length > 0) && apiData.attendance_info[0].checkout_time){
        //   Swal.fire({
        //     text: 'Attendance Marked Already!',
        //     icon: 'warning',
        //     heightAuto: false,
        //     showConfirmButton: false
        //   })
        // }else{
        // }
      }
  }

  const inRange = (lat, long) => {
    if(position.latitude){
      let positionLoc = {
        latitude: parseFloat(lat),
        longitude: parseFloat(long)
      }
      const mainDistance = geolib.getPreciseDistance(position, positionLoc);
      const distance = geolib.convertDistance(mainDistance, 'm');
      if(distance > 200){
        Swal.fire({
          text: `Out of range`,
          icon: 'warning',
          iconColor: '#000000',
          heightAuto: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }else{
        setIsInRange(true)
      }
    }else{
      Swal.fire({
        text: 'Geolocation is not enabled',
        icon: 'warning',
        confirmButtonColor: '#000',
        heightAuto: false,
      }).then(() => {
        window.close()
      })
    }
  }

  useEffect(() => {
    (async () => {
      // console.log(position)
      let apiData = await getEmployeesData(account_code, location_code);
      if(apiData.status === "S"){
          setAccInfo({
            account_type: apiData.account_type, 
            account_code: apiData.account_code, 
            account_name: apiData.account_name, 
            location_name: apiData.location_name, 
            account_logo: apiData.account_logo,
            latitude: +apiData.location_latitude,
            longitude: +apiData.location_longitude,
            employees: [...apiData.employee_info]
          })
          setEmployees([...apiData.employee_info]);
          if(apiData.location_latitude && position.latitude){
            inRange(apiData.location_latitude, apiData.location_longitude)
          }else{
            setIsInRange(true);
          }
      }else if(apiData.status === "E"){
        Swal.fire({
          text: apiData.message,
          icon: 'warning',
          heightAuto: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false
        })
      }
    })()
  }, [position]);

  useEffect(() => {
    (async () => {
      if("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setPosition({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        }, (err) => {
          Swal.fire({
            text: 'Geolocation is not enabled',
            icon: 'warning',
            heightAuto: false,
            showConfirmButton: false
          })
        });
      }else{
        Swal.fire({
          text: 'Geolocation is not enabled',
          icon: 'warning',
          heightAuto: false,
          showConfirmButton: false
        })
      }
    })();
  }, [])

  return (<>
    {accInfo.account_name && accInfo.account_type === 'ams' && <div className="checkin-page">
      <div className="header">
        <div className="admin-name">
          { !isThank && imgFile ? 'Employee Info': accInfo.account_name}
        </div>
      </div>
      {!isThank && <div className="divider"></div>}
      { !isThank && !imgFile &&
        <div className="body1">
          <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/account_logos/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" />
          <div className='mt-4 w-100'>
            {isInRange && employees && <Autocomplete className='w-100'
              disablePortal
              onInputChange={(event, inputVal) => {
                if(!inputVal){
                  setValue({});
                }
              }}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              id="combo-box-demo"
              options={[...employees]}
              getOptionLabel={(option) => option.emp_name} 
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Employee" />}
            />}
          </div>
          <input type="file" 
            hidden 
            ref={fileIp}
            onClick={(e) => {
              e.target.value = ''; // Reset input to allow re-capture
            }}
            onChange={(ev) => {
              handleImage(ev)
            }}
            id="selfie" 
            name="selfie"
            accept="image/*" 
            capture
          />
          {!position.latitude && <p className='text-danger mt-2 mb-0'>Geolocation not enabled</p>}
          {isInRange && <button className="btn btn-dark btn-black mt-3 rounded-pill px-4" disabled={(!position.latitude || !value?.emp_code) ? true : false} id="btnIp" onClick={() => {
            // if(accInfo.account_type === "ams"){
            //   fetchAttendanceLog();
            // }
            checkCameraAccess('user')
          }}>Mark Attendance</button>}
        </div>
      }
      {!isThank && imgFile && 
        <div className="body2">
        <img src={img ? img : 'https://placehold.co/100x150'} alt="" />
        <div className='mt-4'>
          <TextField className="mb-3 w-100" focused label="Employee Name" disabled value={value.emp_name} />
          <TextField className="mb-3 w-100" focused label="Mobile Number" disabled value={value.phone} />
          <TextField className="mb-3 w-100" focused label="Designation" disabled value={value.designation} />
          <TextField className="mb-3 w-100" focused label="Employee Code" disabled value={value.emp_code} />
        </div>
        {!empLog.checkout_time && <div className="d-flex justify-content-between w-100">
          <button className="btn btn-dark btn-black mt-3 rounded-pill px-4 w-40" id="btnIp" disabled={empLog.checkin_time} onClick={() => {submit('login')}}>Login</button>
          <button className="btn btn-dark btn-black mt-3 rounded-pill px-4 w-40" id="btnIp" disabled={!empLog.checkin_time} onClick={() => {submit('logout')}}>Logout</button>
        </div>}
        {empLog.checkout_time && <div className="w-100 mt-3">
          <h4 className="text-center text-danger">
          <strong>Attendance Marked Already!</strong>
          </h4>
        </div>}
      </div>
      }
      {isThank && <div className="body">
        <img src={accInfo.account_logo ? 'https://attendlog.com/api/files/account_logos/'+accInfo.account_logo : 'https://placehold.co/250x70'} alt="" />
        <div className='w-100 text-center'>
          <i className="fa-regular fa-check-circle text-success"></i>
          {accInfo.account_type === 'cms' && <p className="mt-3 mb-4">Your checkin is successful</p>}
          {accInfo.account_type === 'ams' && <p className="mt-3 mb-4">{logType} is successful</p>}
        </div>
        <p>powered by <span className='text-warning'>AttendLog</span></p>
      </div>}
    </div>}
  </>)
}

export default CaptureLogin