import React, { useEffect, useState } from 'react'
import "./reports.css"
import { TextField } from '@mui/material'
import { getReports } from '../../Api';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

function Reports() {
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [accountCode, setAccountCode] = useState(null);
    const date = new Date();

    function convert24To12HourFormat(timeStr) {
      const [hours, minutes, seconds] = timeStr.split(':');
      const period = hours >= 12 ? 'PM' : 'AM';
      const hour12 = hours % 12 || 12;
      return `${hour12}:${minutes}:${seconds} ${period}`;
    }

    useEffect(() => {
      const search = location.search; // Get the query string including '?'
      const valueAfterQuestionMark = search.slice(1); // Remove the '?' to get 'upwiq'
      setAccountCode(valueAfterQuestionMark); // Output: 'upwiq'
    }, [location]);

    useEffect(() => {
        (async () => {
          if(accountCode){
            let apiData = await getReports(accountCode);
            if (apiData.status === "S"){
              setUserInfo(apiData.account_info);
              setTableData(apiData.result_info);
            }
          }
            // apiData.status === "S" ?  setTableData(apiData.result_info) : setTableData([]);
        })();
    }, [accountCode])


    
    return (
        <div className='reportsPage'>
          {/* <h5>Report page working</h5>
          <p>Account Code: {accountCode}</p> */}

          {/* <h5 className='manage-employee'>Attendance Log</h5> */}
          <div className='manage-employee text-center mb-1'>
           {userInfo && <img src={userInfo.logo ? 'https://attendlog.com/api/files/account_logos/'+userInfo.logo : ''} style={{maxHeight: 100}} alt="" />}
          </div>
          <h5 className='text-center mb-4'><strong>Attendance Report - {dayjs(date).format('DD-MMM-YYYY')}</strong></h5>
            {/* <div className='searchbar-div mb-4'>
                <div className='searchbar'>
                    <TextField id="standard-basic" label="Search" variant="standard" value={searchQuery} onChange={handleSearchChange} />
                </div>
            </div> */}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography className='d-flex justify-content-between w-100'><span><strong>Late</strong></span> <span className="ms-auto me-2"><strong>{tableData && tableData.lateRecords.length}</strong></span></Typography>
              </AccordionSummary>
              <AccordionDetails>
                  {tableData && tableData.lateRecords && tableData.lateRecords.length > 0 && <div className="card table-card ofw-hid p-0">
                    <div className="tableContainer location-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Employee Name</th>
                                {/* <th scope="col">Location</th> */}
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Login Time</th>
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Logout Time</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.lateRecords && tableData.lateRecords.map((row, index) => (
                                    <tr key={index}>
                                        <td scope="col">{row.emp_name}</td>
                                        {/* <td scope="col">{row.location_name}</td> */}
                                        <td scope="col" className='text-center'>{row.checkin_time ? convert24To12HourFormat(row.checkin_time) : 'NA'}</td>
                                        <td scope="col" className='text-center'>{row.checkout_time ? convert24To12HourFormat(row.checkout_time) : 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                    </div>
                  </div>}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography className='d-flex justify-content-between w-100'><span><strong>On-Time</strong></span> <span className="ms-auto me-2"><strong>{tableData && tableData.onTimeRecords.length}</strong></span></Typography>
              </AccordionSummary>
              <AccordionDetails>
                {tableData && tableData.onTimeRecords && tableData.onTimeRecords.length > 0 && <div className="card table-card ofw-hid p-0">
                    <div className="tableContainer location-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Employee Name</th>
                                {/* <th scope="col">Location</th> */}
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Login Time</th>
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Logout Time</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.onTimeRecords && tableData.onTimeRecords.map((row, index) => (
                                    <tr key={index}>
                                        <td scope="col">{row.emp_name}</td>
                                        {/* <td scope="col">{row.location_name}</td> */}
                                        <td scope="col" className='text-center'>{row.checkin_time ? convert24To12HourFormat(row.checkin_time) : 'NA'}</td>
                                        <td scope="col" className='text-center'>{row.checkout_time ? convert24To12HourFormat(row.checkout_time) : 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                    </div>
                  </div>}
              </AccordionDetails>
            </Accordion>
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography className='d-flex justify-content-between w-100'><span><strong>Absent / No Scan</strong></span> <span className="ms-auto me-2"><strong>{tableData && tableData.absentRecords.length}</strong></span></Typography>
              </AccordionSummary>
              <AccordionDetails>
                  {tableData && tableData.absentRecords && tableData.absentRecords.length > 0 && <div className="card table-card ofw-hid p-0">
                    <div className="tableContainer location-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Employee Name</th>
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Login Time</th>
                                <th scope="col" className='text-center' style={{textWrap: 'nowrap'}}>Logout Time</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableData && tableData.absentRecords && tableData.absentRecords.map((row, index) => (
                                    <tr key={index}>
                                        <td scope="col">{row.emp_name}</td>
                                        <td scope="col" className='text-center'>{row.checkin_time ? row.checkin_time : 'NA'}</td>
                                        <td scope="col" className='text-center'>{row.checkout_time ? row.checkout_time : 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                    </div>
                  </div>}
              </AccordionDetails>
            </Accordion> */}
        </div>
    )
}

export default Reports
